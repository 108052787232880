import API from "@/api";

export default {
  getStatisticsList: params => {
    return API.requestPost({
      url: "sponsored/statisticsTable",
      data: params,
      auth: true
    });
  }
};
